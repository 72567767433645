<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 用户列表区域 -->
      <el-table :data="rightsList" border style="width: 100%" :stripe="true">
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column prop="authName" label="权限名称" align="center"></el-table-column>
        <el-table-column prop="path" label="路径" align="center"></el-table-column>
        <el-table-column label="权限等级" align="center">
          <template slot-scope="scope">
            <!-- 标签等级 -->
            <el-tag v-if="scope.row.level === '1'">一级</el-tag>
            <el-tag type="success" v-else-if="scope.row.level === '2'">二级</el-tag>
            <el-tag type="warning" v-else-if="scope.row.level === '3'">三级</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      // 权限列表
      rightsList: []
    }
  },
  created () {
    this.getRightsList()
  },
  methods: {
    // 获取权限列表
    async getRightsList () {
      const { data: res } = await this.$http.get('rights/list')
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: res.meta.msg || '获取权限列表失败',
          center: true,
          duration: 1000
        })
      }
      this.rightsList = res.data
    }
  }
}
</script>
