<template>
  <div>
   <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 添加角色区域 -->
      <el-row>
        <el-col>
          <el-button type="primary" @click="addRoleDialogVisible = true">添加角色</el-button>
        </el-col>
      </el-row>
      <!-- 角色列表区域 -->
      <el-table :data="rolesList" border style="width: 100%" :stripe="true" row-key="id">
        <!-- 展开列 -->
        <el-table-column type="expand" label="#" align="center">
          <template slot-scope="scope">
            <el-row v-for="(item1, i1) in scope.row.children" :key="item1.id" :class="['bd-bottom',i1 === 0 ? 'bd-top':'','row-center']">
              <!-- 渲染一级权限 -->
              <el-col :span="5">
                <el-tag closable @close="removeRightsById(scope.row,item1.id)">{{item1.authName}}</el-tag>
                <i class="el-icon-caret-right"></i>
              </el-col>
              <!-- 渲染二级权限和三级权限 -->
              <el-col :span="19">
                <el-row v-for="(item2, i2) in item1.children" :key="item2.id" :class="[i2 === 0 ? '' : 'bd-top','row-center']">
                  <el-col :span="6">
                    <el-tag type="success" closable @close="removeRightsById(scope.row,item2.id)">{{item2.authName}}</el-tag>
                    <i class="el-icon-caret-right"></i>
                  </el-col>
                  <el-col :span="18">
                    <el-tag type="warning" v-for="(item3) in item2.children" :key="item3.id" closable @close="removeRightsById(scope.row,item3.id)">{{item3.authName}}</el-tag>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <!-- 索引列 -->
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column prop="roleName" label="角色名称" align="center"></el-table-column>
        <el-table-column prop="roleDesc" label="角色描述" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-tooltip content="编辑" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini"></el-button>
            </el-tooltip>
            <el-tooltip content="删除" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini"></el-button>
            </el-tooltip>
            <el-tooltip content="分配权限" placement="top" :enterable="false">
              <el-button type="warning" icon="el-icon-setting" size="mini" @click="showSetRightsDialog(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="分配权限" :visible.sync="setRightsDialogVisible" width="25%" :center="true" @close="setRightsDialogClosed">
      <el-tree :data="rightsList" :props="defaultProps" node-key="id" ref="rightsTreeRef" default-expand-all :default-checked-keys="defKeys" show-checkbox highlight-current></el-tree>
      <span slot="footer">
        <el-button @click="setRightsDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="setRights">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      // 角色列表
      rolesList: [],
      // 控制添加角色对话框显示/隐藏
      addRoleDialogVisible: false,
      // 权限列表
      rightsList: [],
      // 控制分配权限对话框显示/隐藏
      setRightsDialogVisible: false,
      defaultProps: {
        children: 'children',
        label: 'authName'
      },
      // 默认勾选的权限id
      defKeys: [],
      // 保存分配权限的角色id
      roleId: ''
    }
  },
  created () {
    this.getRolesList()
  },
  methods: {
    // 获取所有角色的列表
    async getRolesList () {
      const { data: res } = await this.$http.get('roles')
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '获取角色列表失败',
          center: true,
          duration: 1000
        })
      }
      this.rolesList = res.data
    },
    // 根据角色id删除权限
    async removeRightsById (role, rightsId) {
      const result = await this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (result !== 'confirm') {
        return this.$message({
          type: 'info',
          message: '已取消删除操作',
          center: true,
          duration: 1000
        })
      }
      // 删除成功返回的是该角色最新的权限数据
      const { data: res } = await this.$http.delete(`roles/${role.id}/rights/${rightsId}`)
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '删除权限失败',
          center: true,
          duration: 1000
        })
      }
      // 更新角色列表
      // this.getRolesList() // 会重新刷新整个列表，导致展开列会自动合并，用户体验不好
      role.children = res.data // 只更新当前角色的权限数据
      this.$message({
        type: 'success',
        message: '删除权限成功',
        center: true,
        duration: 1000
      })
    },
    getRightsKeys (node, array) {
      // 只获取三级权限id，三级权限没有children
      if (!node.children) {
        array.push(node.id)
      } else {
        node.children.forEach(item => {
          this.getRightsKeys(item, array)
        })
      }
    },
    // 分配权限
    async showSetRightsDialog (role) {
      // 获取树状权限数据
      this.roleId = role.id
      const { data: res } = await this.$http.get('rights/tree')
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '获取树状权限失败',
          center: true,
          duration: 1000
        })
      }
      // 获取树状权限数据
      this.rightsList = res.data
      // 获取默认已有的权限id
      this.getRightsKeys(role, this.defKeys)
      this.setRightsDialogVisible = true
    },
    // 关闭分配权限对话框
    setRightsDialogClosed () {
      // 清空id数据
      this.defKeys = []
    },
    async setRights () {
      const keys = [...this.$refs.rightsTreeRef.getCheckedKeys(), ...this.$refs.rightsTreeRef.getHalfCheckedKeys()]
      const idStr = keys.join(',')
      console.log(idStr)
      const { data: res } = await this.$http.post(`roles/${this.roleId}/rights`, {
        rids: idStr
      })
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '分配权限失败',
          center: true,
          duration: 1000
        })
      }
      this.$message({
        type: 'success',
        message: '分配权限成功',
        center: true,
        duration: 1000
      })
      // 更新角色列表
      this.getRolesList()
      // 隐藏分配权限对话框
      this.setRightsDialogVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
  .el-tag{
    margin: 7px;
  }
  .bd-top{
    border-top: 1px solid #eee;
  }
  .bd-bottom{
    border-bottom: 1px solid #eee;
  }
  .row-center{
    display: flex;
    align-items: center;
  }
</style>
